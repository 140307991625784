<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @filter-change="filterChange"
        @sort-change="sortChange"
        style="width: 100%">
        <el-table-column
          prop="caseId"
          sortable="custom"
          label="求助ID">
        </el-table-column>
        <el-table-column
          prop="caseAccountId"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="publisherName"
          label="真实姓名">
        </el-table-column>
        <el-table-column
          prop="caseMainType"
          :filters="applyFilter"
          column-key="caseMainType"
          label="求助类型">
          <template slot-scope="scope">
            <span style="color:#409EFF;" v-if="scope.row.caseMainType==1">诉讼业务</span>
            <span style="color:#67C23A;" v-else-if="scope.row.caseMainType==2">非诉讼业务</span>
            <div>{{scope.row.caseTypeName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="areaName"
          label="求助区域">
          <template slot-scope="scope">
            {{scope.row.provinceName}}&nbsp;{{scope.row.cityName}}&nbsp;{{scope.row.areaName}}
          </template>
        </el-table-column>
        <el-table-column
          prop="identity"
          :filters="typeFilter"
          column-key="identity"
          label="发布者身份">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.blue}" v-show="scope.row.identity==1">原告</span>
            <span :style="{'color':COLOR.red}"  v-show="scope.row.identity==2">被告</span>
            <span :style="{'color':COLOR.green}"  v-show="scope.row.identity==3">代理人</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          sortable="custom"
          label="求助提交时间">
        </el-table-column>
        <el-table-column
          prop="deadline"
          sortable="custom"
          label="报名截止时间">
        </el-table-column>
        <el-table-column
          width="160"
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="checkHandle(scope.row)">求助动态</el-button>
              <el-divider direction="vertical"></el-divider> -->
              <el-button type="text" @click="userHandle(scope.row)">进行审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <seek-help-dialog ref="seekHelpDialog" :info="info" @ensure="ensure"></seek-help-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import SeekHelpDialog from './coms/seek-help-dialog.vue'
import {waitVerifyHelpList,caseDetail,verify} from '../../api/verify'
export default {
  name:'seekHelp',
  data () {
    return {
      total:0,
      form:{
        page:1,
        perPage:10,
        businessType:1,
        sortWay:'',
        caseMainType:'',
        identity:''
      },
      info:{},
      conditions:[
        {value:'caseId',label:'求助ID'},
        {value:'accountId',label:'用户ID'},
        {value:'publisherName',label:'真实姓名'},
      ],
      tableData:[
        {
          val:'1',
          id:1,
          status:'1'
        },
        {
          val:'2',
          id:2,
          status:'2'
        },
        {
          val:'3',
          id:3,
          status:'3'
        },
        {
          val:'4',
          id:4,
          status:'4'
        }
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: 1}, 
          {text: '非诉讼业务', value: 2}
        ],
        typeFilter:[
          {text: '原告', value: 1}, 
          {text: '被告', value: 2}, 
          {text: '代理人', value: 3}, 
        ],

    };
  },
  components: {
    TableSearch,
    SeekHelpDialog
  },
  computed: {},
  methods: {
    ensure(form){
      let para = {
        caseId:this.info.caseId,
        verifyResult:form.verifyResult,
        comments:form.comments
      }
      verify(para).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.$message.success(data.desc)
          this.getList()
        }else{
          this.$message.warning(data.desc)
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        businessType:1,
        caseMainType:this.form.caseMainType,
        identity:this.form.identity
        },para);
      this.getList();
    },
    getList(){
      waitVerifyHelpList(this.form).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();

    },
    handleSizeChange(val){
      this.form.page =1;
      this.form.perPage = val;
      this.getList();
    },
    sortChange(sort){
      if(sort.prop =='caseId'){
        this.form.sortWay = sort.order==='ascending'?1:2;
      }
      if(sort.prop =="createTime"){
        this.form.sortWay = sort.order==='ascending'?3:4;
      }
      if(sort.prop =="deadline"){
        this.form.sortWay = sort.order==='ascending'?5:6;
      }
      this.getList();
    },
    addHandle(){},
    checkHandle(row){},
    userHandle(row){
      this.$refs.seekHelpDialog.dialogVisible = true;
      this.getDetail(row.caseId);
    },
    getDetail(caseId){
      caseDetail({caseId:caseId}).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.info = data.content;
        }else{
          console.log(data.desc)
        }
      })
    },
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    filterChange(filters){
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>